.hamburger-button {
  position: fixed;
  top: 50%;
  left: 10;
  transform: translateY(50%);
  z-index: 1000;
  background-color: white; /* Ensure the button has a background to be visible */
}

.side-navbar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 250px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  transition: left 0.3s ease;
  z-index: 999;
}

.side-navbar.open {
  left: 0;
}

nav a {
  margin-bottom: 20px;
  text-decoration: none;
  color: #575757;
  transform: rotate(0deg); /* Ensure text remains horizontal */
}

nav a:hover {
  text-decoration: none;
  color: #ec704c;
}

nav a.active {
  color: #ec704c;
}

.nav-text {
  transform: rotate(0deg); /* Ensure text remains horizontal */
}


nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 30%;
  left: 45px;
  transform: rotate(0deg);
}
nav a {
  margin-bottom: 20px;
  text-decoration: none;
  color: #575757;
  transform: rotate(0deg); /* Ensure text remains horizontal */
}
nav a:hover {
  text-decoration: none;
  color: #ec704c;
}
nav a.active {
  color: #ec704c;
}
.nav-text {
  transform: rotate(0deg); /* Ensure text remains horizontal */
}
